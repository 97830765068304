import './Vehicle.scss';
import React, { useState, useEffect, useContext } from 'react';
import Grid from '@mui/material/Grid';
import isEmpty from 'lodash/isEmpty';
import MaterialMultiSelect from '../../../materialMultiSelect/MaterialMultiSelect';

import { useAsync } from '../../../../utils/useAsync';
import { removeEmptyValues } from '../../../../utils/objectUtils';
import {
  getManufacturersAndMakes,
  getAvailableVehicles,
  getTypes,
  getSubTypes,
  getClasses,
  getClassSizes,
  getFuels,
} from '../../../../services/vehicles.api';
import RangeSelector from '../../../rangeSelector/RangeSelector';
import { StoreContext } from '../../../../store/storeContext';
import FormField from '../../../formField/FormField';

function Vehicle({ vehiclesSelection, onChange, resetState, setResetState }) {
  const [types, setTypes] = useState([]);
  const [subTypes, setSubTypes] = useState([]);
  const [beginModelYear, setBeginModelYear] = useState({});
  const [endModelYear, setEndModelYear] = useState({});
  const [classes, setClasses] = useState([]);
  const [classSizes, setClassSizes] = useState([]);
  const [fuels, setFuels] = useState([]);
  const [availableModels, setAvailableModels] = useState({});
  const [models, setModels] = useState([]);
  const [makes, setMakes] = useState([]);
  const [availableMakes, setAvailableMakes] = useState([]);
  const { actions } = useContext(StoreContext);
  const { data: availableVehicles, run: runAvailableVehicles } = useAsync();
  const [fetchedManufacturers, setFetchedManufacturers] = useState([]);
  const [fetchedMakes, setFetchedMakes] = useState([]);
  const [selectedManufacturers, setSelectedManufacturers] = useState([]);
  const [selectedMakes, setSelectedMakes] = useState([]);

  const populateModels = (data) => {
    let result = {};
    data.forEach((manufacturer) => {
      manufacturer.makes.forEach((make) => {
        result = { ...result, [make.makeId]: make.models };
      });
    });
    setAvailableModels(result);
    if (fetchedMakes.length === 0) {
      setFetchedMakes(data);
    }
  };

  const populateMakes = (data) => {
    let result = {};
    data.forEach(({ manufacturerId, makes: m }) => {
      result = { ...result, [manufacturerId]: m };
    });
    setAvailableMakes(result);
    if (fetchedManufacturers.length === 0) {
      setFetchedManufacturers(data);
      let listOfFetchedMakes = [];
      data.forEach((manufacturer) => {
        listOfFetchedMakes = listOfFetchedMakes.concat(manufacturer.makes);
      });
      setFetchedMakes(listOfFetchedMakes);
    }
  };

  useEffect(() => {
    setSelectedManufacturers([]);
    setSelectedMakes([]);
  }, [resetState]);

  useEffect(() => {
    populateModels(fetchedManufacturers);
  }, [fetchedMakes]);

  useEffect(() => {
    getTypes()
      .then((data) => setTypes(data))
      .catch((error) => {
        actions.errorAction(error);
      });
    getSubTypes()
      .then((data) => setSubTypes(data))
      .catch((error) => {
        actions.errorAction(error);
      });
    getClasses()
      .then((data) => setClasses(data))
      .catch((error) => {
        actions.errorAction(error);
      });
    getClassSizes()
      .then((data) => setClassSizes(data))
      .catch((error) => {
        actions.errorAction(error);
      });
    getFuels()
      .then((data) => setFuels(data))
      .catch((error) => {
        actions.errorAction(error);
      });
    getManufacturersAndMakes()
      .then((data) => populateMakes(data))
      .catch((error) => {
        actions.errorAction(error);
      });
  }, []);

  useEffect(() => {
    if (!isEmpty(availableModels)) {
      setModels(
        Object.values(availableModels)
          .reduce((arr, model) => arr.concat(model), [])
          .sort((a, b) => (a.modelTx > b.modelTx ? 1 : -1)),
      );
    }
  }, [availableModels]);

  useEffect(() => {
    if (!isEmpty(availableMakes)) {
      // get makes based on manufacturers
      setMakes(
        Object.values(availableMakes)
          .reduce((arr, make) => arr.concat(make), [])
          .sort((a, b) => (a.makeTx > b.makeTx ? 1 : -1)),
      );
    }
  }, [availableMakes]);

  useEffect(() => {
    runAvailableVehicles(getAvailableVehicles());
  }, [runAvailableVehicles]);

  const selectMakesbyManufacturerSelection = () => {
    let makesForSelectedManufacturers = [];
    if (selectedManufacturers.length > 0) {
      selectedManufacturers.forEach((selectedManufacturer) => {
        const makesForSpecificManufacturer = fetchedManufacturers.filter(
          (manufacturer) => manufacturer.manufacturerId === selectedManufacturer,
        )[0].makes;
        makesForSelectedManufacturers = makesForSelectedManufacturers.concat(
          makesForSpecificManufacturer,
        );
      });
      setAvailableMakes(makesForSelectedManufacturers);
    } else {
      populateMakes(fetchedManufacturers);
    }
  };

  useEffect(() => {
    selectMakesbyManufacturerSelection();
  }, [selectedManufacturers]);

  const selectModelsbyManufacturersAndMakesSelection = () => {
    let modelsForSelectedManufacturersAndMakes = [];
    if (selectedMakes.length > 0) {
      selectedMakes.forEach((selectedMake) => {
        const modelsForSpecificMake = fetchedMakes.filter((make) => make.makeId === selectedMake)[0]
          .models;
        modelsForSelectedManufacturersAndMakes =
          modelsForSelectedManufacturersAndMakes.concat(modelsForSpecificMake);
      });
      setAvailableModels(modelsForSelectedManufacturersAndMakes);
    } else if (selectedManufacturers.length > 0) {
      selectedManufacturers.forEach((selectedManufacturer) => {
        const tempManufacturer = fetchedManufacturers.filter(
          (manufacturer) => manufacturer.manufacturerId === selectedManufacturer,
        );
        const tempMakes = tempManufacturer[0]?.makes;
        tempMakes.forEach((tempMake) => {
          const modelsForSpecificMake = tempMake.models;
          modelsForSelectedManufacturersAndMakes =
            modelsForSelectedManufacturersAndMakes.concat(modelsForSpecificMake);
        });
      });
      setAvailableModels(modelsForSelectedManufacturersAndMakes);
    } else {
      populateModels(fetchedManufacturers);
    }
  };

  const handleManufacturersChange = (data, queryType, option, typeOfChange) => {
    const { ...auxVehiclesSelection } = vehiclesSelection ?? {};
    if (option === 'all' || option === 'clear') {
      setSelectedManufacturers([]);
      // selectModelsbyMakesSelection();
    } else {
      const { manufacturerId } = option;
      if (typeOfChange === 'selectOption') {
        const selectedManufacturersArray = [];
        Object.keys(data.manufacturers).forEach((k) => {
          selectedManufacturersArray.push(data.manufacturers[k].manufacturerId);
        });
        setSelectedManufacturers(selectedManufacturersArray);
      } else {
        // handle remove selection
        const newSelectedManufacturersArray = selectedManufacturers.filter(
          (manufacturer) => manufacturer !== manufacturerId,
        );
        setSelectedManufacturers(newSelectedManufacturersArray);
        // setSelectedMakes(newSelectedManufacturersArray);
      }
    }
    onChange({
      vehiclesSelection: {
        ...removeEmptyValues({ ...auxVehiclesSelection, ...data }),
      },
    });
  };

  useEffect(() => {
    selectModelsbyManufacturersAndMakesSelection();
  }, [selectedMakes]);

  useEffect(() => {
    selectModelsbyManufacturersAndMakesSelection();
  }, [selectedManufacturers]);

  const handleMakesChange = (data, queryType, option, typeOfChange) => {
    const { ...auxVehiclesSelection } = vehiclesSelection ?? {};
    if (option === 'all' || option === 'clear') {
      setSelectedMakes([]);
    } else {
      const { makeId } = option;
      if (typeOfChange === 'selectOption') {
        const selectedMakesArray = [];
        Object.keys(data.makes).forEach((k) => {
          selectedMakesArray.push(data.makes[k].makeId);
        });
        setSelectedMakes(selectedMakesArray);
      } else {
        // handle remove selection
        const newSelectedMakesArray = selectedMakes.filter((make) => make !== makeId);
        setSelectedMakes(newSelectedMakesArray);
      }
    }
    onChange({
      vehiclesSelection: {
        ...removeEmptyValues({ ...auxVehiclesSelection, ...data }),
      },
    });
  };

  const handleInnerChange = (data) => {
    const { ...auxVehiclesSelection } = vehiclesSelection;
    onChange({
      vehiclesSelection: {
        ...removeEmptyValues({ ...auxVehiclesSelection, ...data }),
      },
    });
  };

  const handleModelYearChange = (selector, element) => {
    if (selector === 'start') {
      setBeginModelYear({ element });
    } else {
      setEndModelYear({ element });
    }
  };

  useEffect(() => {
    const { ...auxVehiclesSelection } = vehiclesSelection;
    if (!(isEmpty(beginModelYear) && isEmpty(endModelYear))) {
      onChange({
        vehiclesSelection: {
          ...removeEmptyValues({
            ...auxVehiclesSelection,
            beginModelYear: {
              [beginModelYear?.element?.modelYearId]: beginModelYear?.element,
            },
            endModelYear: {
              [endModelYear?.element?.modelYearId]: endModelYear?.element,
            },
          }),
        },
      });
    } else if (
      !isEmpty(vehiclesSelection?.beginModelYear) ||
      !isEmpty(vehiclesSelection?.endModelYear)
    ) {
      setBeginModelYear({ element: Object.values(vehiclesSelection?.beginModelYear)[0] });
      setEndModelYear({ element: Object.values(vehiclesSelection?.endModelYear)[0] });
    } else {
      delete auxVehiclesSelection.beginModelYear;
      delete auxVehiclesSelection.endModelYear;
      onChange({
        vehiclesSelection: {
          ...removeEmptyValues({
            ...auxVehiclesSelection,
          }),
        },
      });
    }
  }, [beginModelYear, endModelYear]);

  return (
    <Grid container spacing={5} padding="10px 30px">
      <Grid item container direction="column" spacing={1.5} xs={2.75}>
        <Grid item>
          <FormField
            fieldTitle="Type"
            dataCy="aq-Types-label"
            fieldInput={
              <MaterialMultiSelect
                id="advanceQuery__selectTypeId"
                testId="Types"
                options={types}
                label="Type"
                objectRenderField="vehicleTypeDescription"
                onChange={handleInnerChange}
                queryDataType="types"
                selectAttribute="vehicleTypeId"
                value={vehiclesSelection?.types}
                resetState={resetState}
                setResetState={setResetState}
                dataCy="aq-Types"
              />
            }
          />
        </Grid>
        <Grid item>
          <FormField
            fieldTitle="Sub Type"
            dataCy="aq-SubTypes-label"
            fieldInput={
              <MaterialMultiSelect
                id="advanceQuery__selectSubTypeId"
                testId="SubTypes"
                options={subTypes}
                label="Sub Type"
                objectRenderField="vehicleSubTypeTx"
                onChange={handleInnerChange}
                queryDataType="subTypes"
                selectAttribute="vehicleSubTypeCd"
                value={vehiclesSelection?.subTypes}
                resetState={resetState}
                setResetState={setResetState}
                dataCy="aq-SubTypes"
              />
            }
          />
        </Grid>
      </Grid>
      <Grid item container direction="column" spacing={1.5} xs={2.75}>
        <Grid item>
          <FormField
            fieldTitle="Class"
            dataCy="aq-Classes-label"
            fieldInput={
              <MaterialMultiSelect
                id="advanceQuery__selectClassId"
                testId="Classes"
                options={classes}
                label="Class"
                objectRenderField="vehicleClassTx"
                onChange={handleInnerChange}
                queryDataType="classes"
                selectAttribute="vehicleClassCd"
                value={vehiclesSelection?.classes}
                resetState={resetState}
                setResetState={setResetState}
                dataCy="aq-Classes"
              />
            }
          />
        </Grid>
        <Grid item>
          <FormField
            fieldTitle="Class Size"
            dataCy="aq-ClassSizes-label"
            fieldInput={
              <MaterialMultiSelect
                id="advanceQuery__selectClassSizeId"
                testId="ClassSizes"
                options={classSizes}
                label="Class Size"
                objectRenderField="vehicleClassSizeTx"
                onChange={handleInnerChange}
                queryDataType="classSizes"
                selectAttribute="vehicleClassSizeCd"
                value={vehiclesSelection?.classSizes}
                resetState={resetState}
                setResetState={setResetState}
                dataCy="aq-ClassSizes"
              />
            }
          />
        </Grid>
      </Grid>
      <Grid item container direction="column" spacing={1.5} xs={4}>
        <Grid item>
          <FormField
            fieldTitle="Manufacturer"
            dataCy="aq-Manufacturers-label"
            fieldInput={
              <MaterialMultiSelect
                id="advanceQuery__selectManufacturerId"
                testId="Manufacturers"
                options={availableVehicles?.manufacturers}
                label="Manufacturer"
                objectRenderField="manufacturerTx"
                onChange={handleManufacturersChange}
                queryDataType="manufacturers"
                selectAttribute="manufacturerId"
                value={vehiclesSelection?.manufacturers}
                resetState={resetState}
                setResetState={setResetState}
                dataCy="aq-Manufacturers"
              />
            }
          />
        </Grid>
        <Grid item>
          <FormField
            fieldTitle="Make"
            dataCy="aq-Makes-label"
            fieldInput={
              <MaterialMultiSelect
                id="advanceQuery__selectMakeId"
                testId="Makes"
                options={makes}
                label="Make"
                objectRenderField="makeTx"
                onChange={handleMakesChange}
                queryDataType="makes"
                selectAttribute="makeId"
                value={vehiclesSelection?.makes}
                resetState={resetState}
                setResetState={setResetState}
                dataCy="aq-Makes"
              />
            }
          />
        </Grid>
        <Grid item>
          <FormField
            fieldTitle="Model"
            dataCy="aq-Models-label"
            fieldInput={
              <MaterialMultiSelect
                id="advanceQuery__selectModelId"
                testId="Models"
                options={models}
                label="Model"
                objectRenderField="modelTx"
                onChange={handleInnerChange}
                queryDataType="models"
                selectAttribute="modelId"
                value={vehiclesSelection?.models}
                resetState={resetState}
                setResetState={setResetState}
                dataCy="aq-Models"
              />
            }
          />
        </Grid>
        <Grid item>
          <FormField
            fieldTitle="Model Year"
            dataCy="aq-ModelYear-label"
            fieldInput={
              <RangeSelector
                testId="ModelYear"
                options={availableVehicles?.modelYears.sort((a, b) =>
                  a.modelYear > b.modelYear ? -1 : 1,
                )}
                label="Model Year"
                displayAttribute="modelYear"
                handleChange={handleModelYearChange}
                queryDataType="modelYears"
                selectAttribute="modelId"
                beginModelYear={beginModelYear}
                endModelYear={endModelYear}
                setBeginModelYear={setBeginModelYear}
                setEndModelYear={setEndModelYear}
                onChange={onChange}
                resetState={resetState}
                setResetState={setResetState}
                vehiclesSelection={vehiclesSelection}
                dataCy="aq-ModelYear"
              />
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={2.5}>
        <FormField
          fieldTitle="Fuel"
          dataCy="aq-Fuel-label"
          fieldInput={
            <MaterialMultiSelect
              id="advanceQuery__selectFuelId"
              testId="Fuel"
              options={fuels}
              label="Fuel"
              objectRenderField="vehicleFuelTypeTx"
              onChange={handleInnerChange}
              queryDataType="fuels"
              selectAttribute="vehicleFuelTypeId"
              value={vehiclesSelection?.fuels}
              resetState={resetState}
              setResetState={setResetState}
              dataCy="aq-Fuel"
            />
          }
        />
      </Grid>
    </Grid>
  );
}

export default Vehicle;
