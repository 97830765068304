import './AdvancedQuery.scss';
import React, { useEffect, useState } from 'react';
import RestoreIcon from '@mui/icons-material/Restore';
import RunIcon from '@mui/icons-material/PlayCircleOutline';
import { Button, Modal, Box } from '@mui/material';

import { useHistory } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import { isEmpty, cloneDeep, isEqual } from 'lodash';
import Indicator from './components/indicator/Indicator';
import Collapsable from '../collapsable/Collapsable';
import DealerAndLenderSelection from './components/dealersandLendersSelection/DealersAndLendersSelection';
import Vehicle from './components/vehicle/Vehicle';
import { useAsync } from '../../utils/useAsync';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { getAvailableStatesByClientId } from '../../services/territory.api';
import getDisplayBundle from '../../services/quickQuery.api';
import { useTableauQuery, queryModel } from '../../contexts/TableauQuery';
import TitleSelection from './components/titleSelection/TitleSelection';
import RiskOptionsDefault from './components/riskOptions/RiskOptionsDefault';
import RiskOptionsSelection from './components/riskOptions/RiskOptionsSelection';
import { REPORT_TYPES } from '../../utils/constants/constants';
import { QueryOptionTypes } from '../../utils/constants/enums';
import { SavingUpdatingAndDeleting } from '../savingUpdatingAndDeleting/SavingUpdatingAndDeleting';
import LayoutCriteria from './components/layoutCriteria/LayoutCriteria';
import { useTabs } from '../../contexts/Tabs';
import SelectReportToRun from './components/selectReportToRun/SelectReportToRun';

const availableTerritoryTypes = [
  { territoryTypeId: 1, territoryTypeNm: 'Owner' },
  { territoryTypeId: 2, territoryTypeNm: 'Dealer' },
];

function AdvancedQuery({ reportTypeId, reportPath }) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const isMarketReport = reportTypeId === REPORT_TYPES.marketReportTypeId;
  const isRiskReport = reportTypeId === REPORT_TYPES.riskReportTypeId;
  const queryOptionTypeId = isMarketReport
    ? QueryOptionTypes.MarketAdvancedQuery
    : QueryOptionTypes.RiskAdvancedQuery;

  const advancedQueryKey = `${reportTypeId}-aq`;
  const validationErrors = useState(false);

  const [, setContextQuery] = useTableauQuery();
  const { data: availableStates, run: runAvailableStates } = useAsync();
  const { data: dataBundle, run: runBundle } = useAsync();

  const [query, setQuery] = useLocalStorage(advancedQueryKey, queryModel);

  const [resetState, setResetState] = useState(false);

  // Manage Tabs State
  const { marketReport, riskReport, setMarketReportTabsState, setRiskReportTabsState } = useTabs();
  const tabsState = isMarketReport ? marketReport : riskReport;
  const setTabsState = isMarketReport ? setMarketReportTabsState : setRiskReportTabsState;

  const [openExecuterModal, setOpenExecuterModal] = useState(false);

  const handleCloseModal = () => setOpenExecuterModal(false);

  const [endDate, setEndDate] = useState(null);

  let singleStateReportErrorMessage = '';

  const history = useHistory();

  useEffect(() => {
    runAvailableStates(getAvailableStatesByClientId());
  }, [runAvailableStates]);

  useEffect(() => {
    runBundle(
      getDisplayBundle(
        process.env.REACT_APP_PRODUCTS_PRODUCT_ID_AUTOCOUNT_ACCELERATE,
        reportTypeId,
      ),
    );
  }, [runBundle]);

  const stateIsChosen = Boolean(
    query.territorySelection?.states && Object.keys(query.territorySelection.states),
  );

  const resetQuery = () => {
    setQuery(queryModel);
    setEndDate(null);
    setResetState(true);
  };

  const isExecutiveSummaryReport = () =>
    Object.values(query?.chosenReports).some(
      (report) => report.reportId === parseInt(process.env.REACT_APP_EXECUTIVE_SUMMARY_REPORT_ID),
    );

  const runQuery = () => {
    setContextQuery(query);
    history.push({
      pathname: reportPath,
    });
  };

  const onExpand = (index) => {
    const newArr = [...tabsState];
    newArr[index] = !newArr[index];
    setTabsState(newArr);
  };

  const handleQueryOnChange = (data) => {
    const auxQuery = { ...query, ...data };
    setQuery(auxQuery);
  };

  function cleanUpNonRequiredRiskControls() {
    if (query?.chosenReports && query?.riskOptionsSelection?.riskDynamicFields) {
      const reportIds = Object.keys(query?.chosenReports);
      const riskOptionsSelection = query?.riskOptionsSelection;
      const riskDynamicFields = query?.riskOptionsSelection?.riskDynamicFields;
      const cleanedUpRiskDynamicFields = cloneDeep(query?.riskOptionsSelection?.riskDynamicFields);
      if (
        (reportIds === undefined || reportIds.length === 0) &&
        riskOptionsSelection &&
        riskOptionsSelection.riskDynamicFields
      ) {
        delete riskOptionsSelection.riskDynamicFields;
        handleQueryOnChange({
          riskOptionsSelection,
        });
        return;
      }

      if (riskDynamicFields) {
        Object.keys(riskDynamicFields).forEach((key) => {
          if (reportIds === undefined || reportIds.length === 0 || reportIds.indexOf(key) === -1) {
            delete cleanedUpRiskDynamicFields[key];
          }
        });
        if (Object.keys(cleanedUpRiskDynamicFields).length === 0) {
          delete riskOptionsSelection.riskDynamicFields;
          handleQueryOnChange({
            riskOptionsSelection,
          });
        } else if (!isEqual(riskDynamicFields, cleanedUpRiskDynamicFields)) {
          handleQueryOnChange({
            riskOptionsSelection: {
              ...query?.riskOptionsSelection,
              riskDynamicFields: cleanedUpRiskDynamicFields,
            },
          });
        }
      }
    }
  }

  useEffect(() => {
    cleanUpNonRequiredRiskControls();
  }, [query?.chosenReports]);

  useEffect(() => {
    if (!stateIsChosen) {
      delete query.dealersAndLendersSelection;
      handleQueryOnChange(query);
      if (tabsState[3]) onExpand(3);
    }
  }, [stateIsChosen]);

  const handleOpenModal = () => {
    if (Object.keys(query?.chosenReports).length > 1) {
      setOpenExecuterModal(true);
      return;
    }
    if (
      Object.keys(query?.chosenReports).length === 1 &&
      isRiskReport &&
      query?.riskOptionsSelection &&
      query?.riskOptionsSelection?.riskDynamicFields
    ) {
      const reportId = Object.keys(query?.chosenReports)[0];
      const riskDynamicFieldsForReportId = query?.riskOptionsSelection?.riskDynamicFields[reportId];
      const riskDynamicFieldsForReportControlName = Object.keys(riskDynamicFieldsForReportId)[0];
      if (
        Object.values(riskDynamicFieldsForReportId[riskDynamicFieldsForReportControlName]).length >
        1
      ) {
        setOpenExecuterModal(true);
        return;
      }
    }

    runQuery();
  };

  // -------------------------------------------------------------------<Enable Disable buttons>------------------------------------------
  function areRequiredRunPropertiesPopulated() {
    const { territorySelection } = query;
    const { vehiclesSelection } = query;
    let dateRangeFieldsValidation = true;
    if (!isEmpty(vehiclesSelection?.beginModelYear) || !isEmpty(vehiclesSelection?.endModelYear)) {
      if (
        Object.values(vehiclesSelection?.beginModelYear)[0] === undefined ||
        Object.values(vehiclesSelection?.endModelYear)[0] === undefined ||
        Object.values(vehiclesSelection?.beginModelYear)[0]?.modelYearId >
          Object.values(vehiclesSelection?.endModelYear)[0]?.modelYearId
      )
        dateRangeFieldsValidation = false;
    }
    const reportArray = Object.values(query.chosenReports).filter(
      (e) => e.isSingleStateReport === true,
    );
    let singleStateAbleToRun = true;
    let numberOfStatesChosen = 0;
    if (territorySelection.states)
      numberOfStatesChosen = Object.values(territorySelection.states).length;
    if (reportArray.length > 0 && numberOfStatesChosen !== 1) {
      singleStateReportErrorMessage =
        'Detailed reports are only available to be run with a single state selection.';
      singleStateAbleToRun = false;
    }

    return (
      territorySelection?.states !== undefined &&
      query?.chosenReports &&
      Object.keys(query?.chosenReports).length &&
      dateRangeFieldsValidation === true &&
      singleStateAbleToRun === true &&
      !validationErrors[0]
    );
  }

  function areRequiredRunAndSavePropertiesPopulated() {
    return areRequiredRunPropertiesPopulated() && query.queryName.length;
  }

  const isRunDisabled = () => areRequiredRunPropertiesPopulated();

  const isRunAndSaveDisabled = () => areRequiredRunAndSavePropertiesPopulated();
  // -------------------------------------------------------------------</Enable Disable buttons>------------------------------------------

  return (
    <div className="advancedQuery">
      <div className="advancedQuery__buttons">
        <Button
          id="advancedQuery__generateReportButtonID"
          disabled={!isRunDisabled()}
          variant="contained"
          startIcon={<RunIcon />}
          onClick={handleOpenModal}
          data-cy="aq-Run-button"
        >
          Run Report
        </Button>

        <SavingUpdatingAndDeleting
          runQuery={runQuery}
          isRunAndSaveDisabled={isRunAndSaveDisabled}
          query={query}
          queryOptionTypeId={queryOptionTypeId}
        />

        <Button
          variant="contained"
          id="advancedQuery__resetButtonID"
          className="advancedQuery__resetButton"
          startIcon={<RestoreIcon />}
          onClick={resetQuery}
          data-cy="aq-Reset-button"
        >
          Reset
        </Button>
        <Modal
          open={openExecuterModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <SelectReportToRun
              onChange={handleQueryOnChange}
              query={query}
              setContextQuery={setContextQuery}
              reportPath={reportPath}
            />
          </Box>
        </Modal>
      </div>
      <Paper elevation={0} className="advancedQuery__paper">
        <Collapsable
          title="Report Selection"
          dataCy="aq-ReportSelection"
          contentComponent={
            <TitleSelection
              query={query}
              reportTypeId={reportTypeId}
              availableStates={availableStates}
              territorySelection={query.territorySelection}
              runQuery={runQuery}
              onChange={handleQueryOnChange}
              validationErrorsState={validationErrors}
              resetState={resetState}
              setResetState={setResetState}
              endDate={endDate}
              setEndDate={setEndDate}
              availableTerritoryTypes={availableTerritoryTypes}
              dataBundle={dataBundle}
              singleStateReportErrorMessage={singleStateReportErrorMessage}
            />
          }
          defaultExpanded
          expanded
          description="Select the report you wish to run with the desired query, and click RUN REPORT."
          onExpand={onExpand}
          index={0}
          testId={0}
        />

        <Collapsable
          title="Purchase Profile"
          dataCy="aq-PurchaseProfile"
          contentComponent={
            <Indicator
              indicatorSelection={query.indicatorSelection || {}}
              onChange={handleQueryOnChange}
              resetState={resetState}
              setResetState={setResetState}
            />
          }
          test-id="Indicators"
          defaultExpanded={false}
          expanded={tabsState[2]}
          description="Select the purchase profile criteria for your report."
          onExpand={onExpand}
          index={2}
          testId={2}
        />

        <Collapsable
          title="Vehicles"
          description="Select the vehicle criteria for your report."
          test-id="Vehicles"
          dataCy="aq-Vehicles"
          contentComponent={
            <Vehicle
              vehiclesSelection={query.vehiclesSelection || {}}
              onChange={handleQueryOnChange}
              resetState={resetState}
              setResetState={setResetState}
            />
          }
          expanded={tabsState[5]}
          onExpand={onExpand}
          index={5}
          testId={5}
        />

        <Collapsable
          title="Dealers and Lenders (available only when at least one State is selected)"
          dataCy="aq-DealersAndLenders"
          contentComponent={
            <DealerAndLenderSelection
              dealersAndLenders={query.dealersAndLendersSelection || {}}
              onChange={handleQueryOnChange}
              states={query.territorySelection?.states}
              resetState={resetState}
              setResetState={setResetState}
            />
          }
          test-id="dealersAndLenders"
          expanded={tabsState[3]}
          onExpand={(index) => {
            if (stateIsChosen) onExpand(index);
          }}
          index={3}
          testId={3}
          description="Select the Dealers and Lenders you want in your report."
          disabled={!stateIsChosen}
        />

        {isRiskReport && (
          <RiskOptionsDefault
            riskOptionsSelection={query?.riskOptionsSelection || {}}
            onChange={handleQueryOnChange}
          />
        )}

        {isRiskReport && (
          <Collapsable
            title="Risk Options"
            test-id="RiskOptions"
            dataCy="aq-RiskOptions"
            contentComponent={
              <RiskOptionsSelection
                query={query}
                onChange={handleQueryOnChange}
                validationErrorsState={validationErrors}
                resetState={resetState}
                setResetState={setResetState}
                focusLender={Object.values(query?.riskOptionsSelection?.focusLender || {}).at(0)}
                isExecutiveSummaryReport={isExecutiveSummaryReport()}
              />
            }
            expanded={tabsState[6]}
            onExpand={onExpand}
            index={6}
            testId={6}
          />
        )}

        <Collapsable
          title="Layout"
          dataCy="aq-LayoutCriteria"
          contentComponent={
            <LayoutCriteria
              onChange={handleQueryOnChange}
              isMarketReport={isMarketReport}
              riskLayoutCriteriaSelection={query.riskLayoutCriteriaSelection}
              marketLayoutCriteriaSelection={query.marketLayoutCriteriaSelection}
            />
          }
          test-id="layoutCriteria"
          expanded={tabsState[4]}
          onExpand={onExpand}
          index={4}
          testId={4}
        />
      </Paper>
    </div>
  );
}

export default AdvancedQuery;
